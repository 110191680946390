/** @jsx jsx */
import { DUIButton, DUIText } from "@alch/ui";
import { faCheck, faCross } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, ReactElement, useEffect } from "react";
import { Box, jsx } from "theme-ui";

import { MFV_EVENTS } from "../analytics/events";
import { Heap } from "../analytics/heap";
import { Loading } from "../util/loading";
import Section from "./Section";
import { fullWidthSx } from "./styles";

export interface EmailSentViewProps {
  email: string;
  actionText: string;
  sendEmail(): void;
  result: Loading<any>;
}

const EmailSentView = ({
  email,
  actionText,
  sendEmail,
  result,
  ...boxProps
}: EmailSentViewProps): ReactElement => {
  useEffect(() => {
    Heap.track(MFV_EVENTS.EMAIL_VERIFICATION_SCREEN_SHOWN);
  }, []);

  const renderStatusText = (): ReactElement | undefined => {
    if (result.hasValue) {
      return (
        <DUIText sx={{ color: "success.dark" }}>
          <FontAwesomeIcon icon={faCheck} /> Email resent
        </DUIText>
      );
    }
    if (result.error) {
      return (
        <DUIText sx={{ color: "danger.core" }}>
          <FontAwesomeIcon icon={faCross} />{" "}
          {result.error.message ?? "An unknown error occurred."}
        </DUIText>
      );
    }
    return undefined;
  };

  return (
    <Box {...boxProps}>
      <Section>
        <DUIText size="lg" mb={4}>
          We sent you an email! Go check <b>{email}</b> and click the link in
          email to {actionText}
        </DUIText>
        <DUIButton
          mt={4}
          sx={fullWidthSx}
          size="lg"
          type="submit"
          disabled={result.isLoading}
          onClick={sendEmail}
        >
          Resend email
        </DUIButton>
        <DUIText mt={3} sx={{ textAlign: "center" }}>
          {renderStatusText()}
        </DUIText>
      </Section>
    </Box>
  );
};

export default memo(EmailSentView);
