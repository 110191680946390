/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import MasterLayout from "../components/MasterLayout";
import ResetPasswordView from "../components/ResetPasswordView";
import { requestPasswordReset } from "../http/endpoints";

const ResetPasswordPage = memo(function ResetPasswordPage(): ReactElement {
  const [{ redirectUrl, inviteToken, initialEmail }] = useQueryParams({
    redirectUrl: StringParam,
    inviteToken: StringParam,
    initialEmail: StringParam,
  });
  return (
    <MasterLayout title="Reset Password">
      <ResetPasswordView
        redirectUrl={redirectUrl ?? undefined}
        inviteToken={inviteToken ?? undefined}
        initialEmail={initialEmail ?? undefined}
        requestPasswordReset={requestPasswordReset}
      />
    </MasterLayout>
  );
});
export default ResetPasswordPage;
