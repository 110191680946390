/** @jsx jsx */
import { DUIButton, DUIInput, DUIText } from "@alch/ui";
import { memo, ReactElement, useCallback, useState } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

import DUIInputLabel from "../components/DUIInputLabel";
import EmailSentView from "../components/EmailSentView";
import { fullWidthSx, linkSx } from "../components/styles";
import ValidatingForm from "../components/ValidatingForm";
import { RequestPasswordResetParams } from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";
import QueryLink from "./QueryLink";
import Section from "./Section";

export interface ResetPasswordViewProps extends BoxProps {
  redirectUrl?: string;
  inviteToken?: string;
  initialEmail?: string;
  requestPasswordReset(params: RequestPasswordResetParams): Promise<void>;
}

const ResetPasswordView = ({
  redirectUrl,
  inviteToken,
  initialEmail,
  requestPasswordReset,
  ...boxProps
}: ResetPasswordViewProps): ReactElement => {
  const [email, setEmail] = useState(initialEmail ?? "");
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [result, setResult] = useState(Loading.unloaded<void>());
  const waitForLoad = useWaitForLoad();

  const handleSubmit = useCallback(() => {
    setResult(Loading.inProgress());
    waitForLoad(
      requestPasswordReset({ email, redirectUrl, inviteToken }),
      (result) => {
        if (result.hasValue) {
          setSubmittedEmail(email);
          setResult(Loading.unloaded<void>());
        } else {
          setResult(result);
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [requestPasswordReset, email, redirectUrl, inviteToken]);

  const handleResend = useCallback(() => {
    if (!submittedEmail) {
      console.error("Cannot resend email that was never sent.");
      return;
    }
    setResult(Loading.inProgress());
    waitForLoad(requestPasswordReset({ email: submittedEmail }), setResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [requestPasswordReset, submittedEmail]);

  return (
    <Box {...boxProps}>
      {submittedEmail ? (
        <EmailSentView
          email={submittedEmail}
          actionText="choose a new password."
          sendEmail={handleResend}
          result={result}
        />
      ) : (
        <Box>
          <Section centered={true}>
            <DUIText size="2xl">Reset password</DUIText>
            <DUIText
              size="md"
              mt={2}
              sx={{ maxWidth: "300px", color: "gray.4" }}
            >
              Enter your account's email address and we will send you a password
              reset link.
            </DUIText>
          </Section>
          <Section shaded={true}>
            <ValidatingForm onSubmitPreventDefault={handleSubmit}>
              <DUIInputLabel text="Email">
                <DUIInput
                  type="email"
                  placeholder="gavin@hooli.com"
                  autoComplete="email"
                  required={true}
                  value={email}
                  onValueChange={setEmail}
                />
              </DUIInputLabel>
              <DUIButton
                mt={9}
                sx={fullWidthSx}
                size="lg"
                type="submit"
                disabled={result.isLoading}
              >
                Send reset email
              </DUIButton>
              <DUIText
                mt={5}
                sx={{ textAlign: "center", fontWeight: 500 }}
                size="md"
              >
                <QueryLink sx={linkSx} to="/">
                  Return to Login
                </QueryLink>
              </DUIText>
              {result.error && (
                <DUIText size="lg" mt={3} sx={{ color: "danger.core" }}>
                  {result.error.message}
                </DUIText>
              )}
            </ValidatingForm>
          </Section>
        </Box>
      )}
    </Box>
  );
};

export default memo(ResetPasswordView);
